module.exports = {
    language: {
        name: 'EN'
    },
    // 导航栏
    HeadersData: {
        shouye: '首页',
        zoujin: '走进汇胜',
        gogo: '走进汇胜',
        chanpin: '汇胜产品',
        xinwen: '汇胜新闻',
        jituan: '汇胜集团',
        lianxi: '联系我们',
        yingye:'营业执照',
        contact_style: '联系方式',
        contact_phone: '联系电话',
        brand_web:'品牌网站：中联优谷',
        mphpne: '电话',
        youbian:'邮编',
        chuanzhen: '传真',
        dizhi:'地址',
        returnTop: '返回顶部'
    },
    navigator: {
        navIndex:'首页',
        navBiref:'简介',
        navPro: '产品',
        navtec: '技术',
        navNew: '新闻',
        navDao: '导航'
    },
    cityText: '信息公示',
    homeView: {
        home: '首页',
        tion:'汇胜',
        brief: '简介',
        cp: '产品',
        js: '技术',
        xw: '新闻',
        nw:'汇胜',
        dh: '导航',
        gufen: '股份',
        huisheng: '汇胜',
        register: '注册',
        honorG:'荣誉',
        shares: '股份',
        plain: '平原',
        sulation: '绝缘',
        sales: '销售',
        pack: '包装',
        productM:'汇胜',
        loginUsername: '请输入邮箱/手机号',
        xx:'信息',
        gs:'公示'
    },
    gopage: '走进汇胜',
    pageB: {
        birefText: '汇胜简介',
        cultureText: '汇胜文化',
        culture: '文化',
        techM:'汇胜',
        techText: '汇胜技术',
        honorText: '汇胜荣誉',
        tead:'实干',
        incom: '文化',
        deve:'汇胜',
        honr: '荣誉'
    },
    product: '汇胜产品',
    productB: {
        paperText: '纸管原纸',
        tubeText: '纸管',
        cardText: '绝缘纸板',
        mold: '绝缘成型件'
    },
    newText: '汇胜新闻',
    groupText: '汇胜集团',
    groupB: {
        shares: '汇胜股份',
        plain: '汇胜平原',
        sulation: '汇胜绝缘',
        sales: '汇胜销售',
        pack: '汇胜包装',
        website: '汇胜包装官网'
    },
    contactText: '联系我们',
    // 首页
    images: {
        down: '脚踏实地',
        downwo: "干出成绩",
        value: '为客户谋',
        valuebold: '价值',
        make: '为员工谋',
        makebold: "利益",
        wider: "为股东谋",
        widerbold: "回报",
        ability: "以能力开拓",
        abilitytwo: "以品质生存",
        limit: "挑战极限",
        limitwo: "勇争第一",
        sine: "诚信稳健",
        sinetwo: "卓越共赢",
        dusty: "产业领航",
        dustytwo: "员工富康",
    },
    birefDetail: ' 汇胜集团始创于2001年1月，是中国纸管原纸、纸管、绝缘纸板和绝缘成型件行业龙头企业之一。总部位于山东省潍坊高新区，在潍坊高新区、德州平原县建有两个生产基地。旗下拥有汇胜集团股份有限公司、汇胜集团平原纸业有限公司、潍坊汇胜绝缘技术有限公司、汇胜集团销售有限公司、汇胜包装科技有限公司等子公司。 ',
    productText: '汇胜产品',
    lookText:'查看更多',
    nomore:'暂无更多',
    lookView:'查看详情',
    purper: {
        tubeTitle:'纸管原纸',
        tubePolicy:'质量方针：稳定，领先',
        rawText:'优质的原料供应',
        equText:'先进的技术装备',
        runText:'严格的品质管理',
        manText:'卓越的工匠精神',
        tubeTitles:'纸管',
        tubeTitless:'绝缘纸板',
        tubeTitlesss:'绝缘成型件',
    },
    tab: {
        iconText: '纸管原纸',
        iconText1:'纸管',
        iconText2:'绝缘纸板',
        iconText3:'绝缘成型件',
    },
    tech:'汇胜',
    technologyed: '技术',
    technologyText:'是国家科技部认定的“国家火炬计划重点高新技术企业"',
    technology: {
        firmyText:'中国专利山东明星企业',
        frimyBrief:'被山东省科技厅和山东省知识产权局评为“中国专利山东明星企业”。',
        frimyText2:'F500、F600、F800纸管原纸研发与应用技术水平达到了国际先进水平',
        frimyBrief2:'经山东省科技厅、省经信委联合鉴定，公司F500纸管原纸、F600高耐破纸管原纸、F800高强纸管原纸研发与应用技术水平达到了国际先进水平。',
        frimyText3:'超高压变压器用高密度绝缘纸板产品性能达到国际先进水平，8mm厚超高压高密度绝缘纸板填补了国内空白',
        frimyBrief3:'中国机械工业联合会、山东省科技厅组织的汇胜集团“超高压变压器用高密度绝缘纸板”新产品鉴定会上，与会专家一致认定，由汇胜集团自行研制的超高压变压器用高密度绝缘纸板产品性能达到国际先进水平，8mm厚超高压高密度绝缘纸板填补了国内空白。',
        frimyText4:'四项成果填补国内空白，技术水平达到国内领先',
        frimyBrief4: '公司FB系列防爆型纸管原纸、宝塔管专用F200高息胶纸管原纸、F300纸管原纸及高紧度F400纸管原纸研发项目通过山东省科技厅科学技术成果鉴定。四项成果填补国内空白，技术水平达到国内领先。'
    },
    patentBox: {
        patent1:'中国专利山东明星企业',
        patentBrief1:'被山东省科技厅和山东省知识产权局评为“中国专利山东明星企业”。',
        patent2:'F500、F600、F800纸管原纸研发与应用技术水平达到了国际先进水平',
        patentBrief2:'经山东省科技厅、省经信委联合鉴定，公司F500纸管原纸、F600高耐破纸管原纸、F800高强纸管原纸研发与应用技术水平达到了国际先进水平。',
        patent3:'超高压变压器用高密度绝缘纸板产品性能达到国际先进水平，8mm厚超高压高密度绝缘纸板填补了国内空白',
        patentBrief3:'中国机械工业联合会、山东省科技厅组织的汇胜集团“超高压变压器用高密度绝缘纸板”新产品鉴定会上，与会专家一致认定，由汇胜集团自行研制的超高压变压器用高密度绝缘纸板产品性能达到国际先进水平，8mm厚超高压高密度绝缘纸板填补了国内空白。',
        patent4:'四项成果填补国内空白，技术水平达到国内领先',
        patentBrief4:'公司FB系列防爆型纸管原纸、宝塔管专用F200高息胶纸管原纸、F300纸管原纸及高紧度F400纸管原纸研发项目通过山东省科技厅科学技术成果鉴定。四项成果填补国内空白，技术水平达到国内领先。'
    },
    // 走进汇胜
    gopageText: {
        credit1:'汇胜集团始创于2001年1月，是中国纸管原纸、纸管、绝缘纸板和绝缘成型件行业龙头企业之一。总部位于山东省潍坊高新区，在潍坊高新区、德州平原县建有两个生产基地。旗下拥有汇胜集团股份有限公司、汇胜集团平原纸业有限公司、潍坊汇胜绝缘技术有限公司、汇胜集团销售有限公司、汇胜包装科技有限公司等子公司。',
        credit2:'汇胜致力于把纸管原纸、纸管、绝缘纸板、绝缘成型件做强做大。重视技术研发，坚持进行技术改造、持续追求产品质量稳定领先。纸管原纸产品获评“山东省制造业单项冠军产品”。以优质的原料、严格的品质管理、卓越的工匠精神，制造高品质产品，满足客户需求。以品质和服务成就品牌，汇胜先后获评山东省著名商标、中国驰名商标。各类产品畅销大江南北，并出口日本、韩国、印度等二十多个国家，广受客户好评。',
        credit3:'汇胜高度重视安全和环保工作。坚持“安全第一、预防为主、综合治理”的安全方针和“清洁生产、健康工作、享受生活”的环境方针,实现安全生产，环保指标优于国家和地方标准。',
        credit4:'汇胜崇尚实干、诚信、简单、公平、放权、感恩、竞赛、创新导向的企业文化，坚持“诚信稳健，卓越共赢”的经营理念，以诚信为经营之本，努力创造卓越业绩，为客户谋价值，为员工谋利益，为股东谋回报，脚踏实地，干出成绩，实现共赢。',
        credit5:'汇胜以能力开拓、以品质生存，挑战极限、勇争第一，致力于成为行业内产品最优、技术领先、服务优质、团队卓越、管理精细、效率最高、效益最佳、特色鲜明、并不断为员工创造发展空间的典范，实现“产业领航，员工富康”的企业梦想。'
    },
    theme: {
        topical:'文化主题',
        work:'实干文化',
        down:'脚踏实地，干出成绩',
        idea:'核心理念',
        vision:'企业愿景',
        industry:'产业领航，员工富康',
        worth:'核心价值观',
        quality:'以能力开拓，以品质生存',
        spirit:'企业精神',
        prise:'挑战极限，勇争第一',
        misson:'企业使命',
        interest:'为客户谋价值，为员工谋利益，为股东谋回报',
        special:'专项理念',
        manage:'经营理念',
        steady:'诚信稳健，卓越共赢',
        admin:'管理理念',
        simple:'精细可控，简单公平',
        opera:'工作理念',
        everyday:'珍惜每一天，做好每一点',
        ploy:'用人理念',
        respect:'尊重人，培养人，成就人',
        chara:'特色文化',
        since:'诚信文化',
        compe:'竞赛文化',
        power:'放权文化',
        grat:'感恩文化',
        thank:'简单文化',
        theory:'特色理论',
        edge:'竞争力三层次论',
        priate:'合适人论',
        progress:'进步论',
        strategy:'汇胜',
        tegy:'战略'
    },
    start: {
        startText1:'把纸管原纸、纸管、绝缘纸板、绝缘成型件做强、做大。',
        startText2:'坚持进行技术改造，产品质量 稳定领先，成本效率不断先进。',
        startText3:'建立先进企业管理制度，塑造先进企业文化。',
        startText4:'坚持创新发展。',
        startText5:'强调人才第一。员工收入处于当地领先水平。'
    },
    art: {
        artTitle:'是山东省科学技术厅、山东省财政厅、国家税务总局山东省税务局认定的“高新技术企业”。',
        artLi1:'曾被山东省科技厅和山东省知识产权局评为“中国专利山东明星企业”。',
        artLi2:'经山东省科技厅、省经信委联合鉴定，公司F500纸管原纸、F600高耐破纸管原纸、F800高强纸管原纸研发与应用技术水平达到了国际先进水平。',
        artLi3:'在中国机械工业联合会、山东省科技厅组织的汇胜集团“超高压变压器用高密度绝缘纸板”新产品鉴定会上，与会专家一致认定，由汇胜集团自行研制的超高压变压器用高密度绝缘纸板产品性能达到国际先进水平8mm厚超高压高密度绝缘纸板填补了国内空白。',
        artLi4:'公司FB系列防爆型纸管原纸、宝塔管专用F200高吸胶纸管原纸、F300纸管原纸及高紧度F400纸管原纸研发项目通过山东省科技厅科学技术成果鉴定。四项成果填补国内空白，技术水平达到国内领先。'
    },
    circle: {
        circleText1:'拥有先进的生产流水线，年产能64.9万吨。',
        circleText2:'生产纸管原纸（E级-F800）、BT纸管原纸（BTB-BT200）及FB纸管原纸（FB300、FB500）三大系列纸管原纸，品种齐全。',
        circleText3:'产品质量稳定，强度指标及使用性能均处于国内一流水平。',
        circleText4:'拥有国内专业纸管原纸研发团队，拥有山东省企业技术中心、潍坊市纸管原纸（纺筒纸板）工程技术研究中心，配备先进的研发设备，新产品研发能力处于国内一流水平。',
    },
    circle2: {
        circleText1:'选用本公司优质的纸管原纸生产。',
        circleText2:'专业生产一流的纺纱纸管以及造纸、薄膜、无纺布、冶金、食品包装等行业使用的各类高档纸管。',
        circleText3:'可生产内径为纺纱纸管品种齐全25-605mm、壁厚2-30mm等规格纸管。主导产品包括：高直线度BOPP薄膜专用纸管；高档无缝抛光纸管，具备无接缝、无波浪、高光洁度、高真圆度、高直线度、高硬度及环保等系列优势特征；大口径冶金纸管，具有抗压强度大，平整度高，质量稳定等特点。',
        circleText4:'汇胜以其专利技术为棉纺、毛纺、粘胶及线类产品提供高品质纺纱纸管，具有小口接缝光滑，高真圆度，尺寸精度高，强度大，纱磨表面细腻干净，耐高温、高抗水等特点。无论在新时代的全自动高速络筒机、倍捻机，还是涡流纺及气流纺上，汇胜高品质的纺纱纸管都能协助您提高生产效率及产品品质。',
    },
    circle3: {
        circleText1:'原材料采用进口电子级未漂硫酸盐针叶木浆。',
        circleText2:'技术先进，工艺领先，拥有多项发明专利，能稳定生产厚度0.8-8.0mm绝缘纸板。',
        circleText3:'产品质量符合IEC60641-3-1-2008和GBT19264.3-2013标准，产品通过桂林电科院（机械工业电工材料产品质量监督检测中心）的检验，通过德国TUV实验室MSDS、REACH和ROHS三项检测。',
    },
    circle4: {
        circleText1:'绝缘成型件设计年产能6863吨，其中：绝缘件年产能3000吨；层压纸板年产能3743吨；模压件年产能150万片。',
        circleText2:'可制作1000KV出线装置和全套湿法成型件。',
        circleText3:'模压件及湿法成型件经过X射线检测。',
    },
    paperbox: {
        paperText: '汇胜集团股份有限公司位于山东省潍坊市高新区，是国内高档纸管原纸龙头企业，年产能29.9万吨。其产品主要应用于高强度化纤管、纺纱专用管、造纸用工业纸管、塑料薄膜用纸管、大口径冶金纸管等，是国内外众多知名钢铁、化纤、纺织企业的供应商。公司被国家科技部认定为“国家火炬计划重点高新技术企业”，建有“山东省企业技术中心”，是“中国专利山东明星企业”、山东省特种纸产业联盟理事长单位、山东省专精特新中小企业。',
        paperText2:'汇胜集团平原纸业有限公司位于山东省德州市平原县，是国内高档纸管原纸龙头企业，年产能35万吨。其产品主要用于高强度化纤管、纺纱专用管、造纸用工业纸管、塑料薄膜用纸管、大口径冶金纸管等，是国内外众多知名钢铁、化纤、纺织企业的供应商。公司是高新技术企业，建有“山东省企业技术中心”、“德州市纸管原纸工程技术研究中心”、“德州市‘一企一技术’研发中心”、“德州市纸管原纸重点实验室”四个研发平台，是山东省“专精特新”中小企业、德州市“双50强企业”。',
        paperText3:'潍坊汇胜绝缘技术有限公司位于山东省潍坊市高新区，是高压、特高压变压器用绝缘纸板及绝缘成型件的专业制造商。产品面向国内外，建有“山东省特高压变压器绝缘材料工程技术研究中心”，是全国绝缘材料标准化技术委员会委员、中国电器工业协会绝缘材料分会会员、山东省电工技术学会会员单位。公司被认定为高新技术企业、山东省瞪羚企业、山东省“专精特新”中小企业。主导的“超高压变压器用绝缘纸板研究应用”项目荣获2013年山东省轻工业科学技术进步一等奖；“超高压绝缘产品”项目立项成为“2011年国家科技部火炬计划项目”，并荣获“山东省技术创新优秀成果二等奖”。',
        paperText4:'汇胜集团销售有限公司是汇胜纸管原纸的销售单位，销售网络覆盖全国及世界部分地区，竭诚为国内外合作伙伴提供最优质、可靠的产品及服务。',
        paperText5:'汇胜包装科技有限公司在山东省潍坊市高新区和德州市平原县建有两个生产基地，是纸管专业制造商，年产能达6万吨。凭借母公司在纸管原纸方面强大的原材料供应优势和技术支持，为国内外客户提供薄膜、造纸、金属箔、无纺布、玻璃纤维布等行业使用的各类高档优质纸管。是下游众多行业值得信赖的长期、友好、忠实的业务合作伙伴和供应商。',
    }
}