<template>
  <div class="footBox">
    <div class="footG"></div>
    <div class="footText">
      <ul>
        <li>
          <thead @click="gogo">
            {{ $t("HeadersData.zoujin") }}
          </thead>
          <div class="footD"></div>
          <tr v-for="(item,index) in birefed" :key="index">
            <td @click="bief(item)">{{ item.text }}</td>
          </tr>
        </li>
        <li>
          <thead @click="progo">
            {{ $t("HeadersData.chanpin") }}
          </thead>
          <div class="footD"></div>
          <tr v-for="(item,index) in producted" :key="index">
            <td @click="productGo(item)">{{ item.text }}</td>
          </tr>
        </li>
        <li>
          <thead @click="newgo">
            {{ $t("HeadersData.xinwen") }}
          </thead>
          <div class="footD"></div>
          <tr>
            <td @click="newgo">{{ $t("HeadersData.xinwen") }}</td>
          </tr>
          <tr>
            <td @click="cityClick">{{ $t("cityText") }}</td>
          </tr>
        </li>
        <li>
          <thead @click="groupgo">
            {{ $t("HeadersData.jituan") }}
          </thead>
          <div class="footD"></div>
          <tr v-for="(item,index) in grouped" :key="index">
            <td @click="groupGo(item)">{{ item.text }}</td>
          </tr>
        </li>
        <li>
          <thead @click="relationGo">
            {{ $t("HeadersData.lianxi") }}
          </thead>
          <div class="footD"></div>
          <tr>
            <td @click="relationGo">{{ $t("HeadersData.contact_style") }}</td>
          </tr>
        </li>
      </ul>
      <div class="footRight">
        <div class="footRelation">{{ $t("HeadersData.contact_phone") }}</div>
        <div class="footPhone">
          0536 - 8669008
          <!-- <span>huisheng-core.diytrade.com</span> -->
        </div>
        <div class="footImage">
          <img
            src="../assets/index/weixin.png"
            alt=""
            style="width: 0.48rem; height: 0.48rem"
          />
          <div class="erweima">
            <img :src="foot.wx_img" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="copyBox">
      <div class="copy">
        <div class="copyLeft" @click="icpClick">鲁ICP备05027426号-1</div>
        <div class="copyLeft" @click="gonganClick">
          <img src="../assets/gongan.png" />
          鲁公安网备37079402000682号
        </div>
        <div class="copyLeft">{{foot.copy}}</div>
        <div class="copyLeft" @click="yingyeClick">
          <img src="../assets/yingye.png" style="width: 0.28rem; height: 0.28rem"/>
          营业执照
        </div>
        <!-- <div class="copyRight" @click="copyClick">{{ $t("HeadersData.brand_web") }}</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../utils/request";
export default {
  data() {
    return {
      foot: "",
      paperz: "https://www.zlygu.com/",
    };
  },
  computed: {
    birefed() {
      return [
          {
          text: this.$t("pageB.birefText"),
          id: "1",
        },
        {
          text: this.$t("pageB.cultureText"),
          id: "2",
        },
        {
          text: this.$t("pageB.techText"),
          id: "3",
        },
        {
          text: this.$t("pageB.honorText"),
          id: "4",
        },
      ]
    },
    producted() {
      return [
        {
          text: this.$t("productB.paperText"),
          id: "1",
        },
        {
          text: this.$t("productB.tubeText"),
          id: "2",
        },
        {
          text: this.$t("productB.cardText"),
          id: "3",
        },
        {
          text: this.$t("productB.mold"),
          id: "4",
        },
      ]
    },
    grouped() {
      return [
        {
          text: this.$t("groupB.shares"),
          id: "1",
        },
        {
          text: this.$t("groupB.plain"),
          id: "2",
        },
        {
          text: this.$t("groupB.sulation"),
          id: "3",
        },
        {
          text: this.$t("groupB.sales"),
          id: "4",
        },
        {
          text: this.$t("groupB.pack"),
          id: "5",
        },
      ]
    },
  },
  mounted() {
    this.getFoot();
  },
  methods: {
    getFoot() {
      axios
        .get("/index/contact")
        .then((res) => {
          this.foot = res.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    gogo() {
      this.$router.push({
        path: "/goPage",
      });
    },
    bief(item) {
      this.$emit("father",item.id)
      this.$router.push({
        path: "/goPage",
        query: {
          id: item.id,
        },
      });
    },
    progo() {
      this.$router.push({
        path: "/product",
      });
    },
    productGo(item) {
      this.$emit("father",item.id)
      this.$router.push({
        path: "/product",
        query: {
          id: item.id,
        },
      });
    },
    newgo() {
      this.$router.push({
        path: "/news",
      });
    }, 
    cityClick() {
      this.$router.push({
        path: "/city",
      });
    },
    groupgo() {
      this.$router.push({
        path: "/group",
      });
    },
    groupGo(item) {
      this.$emit("father",item.id)
      this.$router.push({
        path: "/group",
        query: {
          id: item.id,
        },
      });
    },
    relationGo() {
      this.$router.push({
        path: "/contact",
      });
    },
    copyClick() {
      window.open(this.paperz, "_blank");
    },
    icpClick() {
      window.open('https://beian.miit.gov.cn/#/Integrated/index', "_blank");
    },
    gonganClick() {
      window.open('http://www.beian.gov.cn/portal/index.do', "_blank");
    },
    yingyeClick(){
      this.$router.push({
        path: "/yingye",
      });
    }
  },
};
</script>

<style>
.footG {
  width: 100%;
  height: 0.12rem;
  background: #709c12;
}
.footText {
  background: #504e4e;
  padding: 0.8rem 2.4rem 1.4rem;
}
.footText ul {
  display: flex;
  margin: 0;
  padding: 0;
  width: 10rem;
  justify-content: space-between;
}
a {
  text-decoration: none;
}
li {
  list-style: none;
}
.footText {
  display: flex;
  justify-content: space-between;
}
.footText thead,
tr,
td {
  font-size: 0;
}
.footText thead {
  color: #fff;
  font-size: 0.2rem;
  font-weight: 700;
  cursor: pointer;
}
.footD {
  width: 0.2rem;
  height: 0.04rem;
  background: #709c12;
  margin: 0.4rem 0 0.24rem;
}
.footText ul li tr {
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
}
.footText ul li tr td {
  padding-bottom: 0.2rem;
  transition: all 0.3s ease-in-out;
}
.footText ul li tr td:hover {
  color: #fff;
}
.footText ul li td {
  color: rgba(255, 255, 255, 0.4);
  font-size: 0.16rem;
  font-weight: 400;
}
.footRight {
  text-align: right;
  padding-top: 0.2rem;
}
.footRelation {
  color: #fff;
  font-size: 0.16rem;
  font-weight: 700;
}
.footPhone {
  color: #709c12;
  font-size: 0.4rem;
  font-weight: 900;
  margin: 0.24rem 0 0.32rem;
}
.footPhone span {  
  font-size: 0.14rem;
  display: block;
}
.copyBox {
  padding: 0 2.4rem;
  background: #504e4e;
}
.copy {
  border-top: 0.01rem solid rgba(255, 255, 255, 0.4);
  display: flex;
  justify-content: space-between;
  color: rgba(255, 255, 255, 0.4);
  font-size: 0.14rem;
  font-weight: 400;
  align-items: center;
  line-height: 0.6rem;
  cursor: pointer;
}
.footImage {
  position: relative;
  margin-left: 3.2rem;
  cursor: pointer;
}
.footImage img {
  width: 100%;
  height: 100%;
  display: block;
}
.erweima {
  position: absolute;
  top: 0.65rem;
  right: 0;
  width: 1.58rem;
  height: 1.58rem;
  opacity: 0;
  transition: all 0.5s;
}
.footImage:hover .erweima {
  opacity: 1;
}
</style>