<template>
  <div class="container">
    <full-page :options="options" ref="fullpage">
      <!-- 一 -->
      <div class="section">
        <div class="banner">
          <div
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              z-index: 99;
            "
          >
            <heade
              :routePath="routePath"
              :fullIndex="fullIndex"
              @changeHome="changeHome"
            ></heade>
          </div>
          <el-carousel
            indicator-position="outside"
            height="100vh"
            ref="carousel"
            :interval="10000"
            :autoplay="true"
            arrow="never"
            @change="changeData"
          >
            <el-carousel-item v-for="item in images" :key="item.index">
              <img :src="item.url" alt="" />
              <div class="down">
                <span>{{ item.text }}</span>
                <span>{{ item.textwo }}</span>
              </div>
              <div class="earth" v-if="item.id == '2'">
                <p>
                  {{ item.value }} <span>{{ item.valuebold }}</span>
                </p>
                <p>
                  {{ item.make }} <span>{{ item.makebold }}</span>
                </p>
                <p>
                  {{ item.wider }} <span>{{ item.widerbold }}</span>
                </p>
              </div>
            </el-carousel-item>
          </el-carousel>
          <div class="cusor">
            <div class="cusorIamge" @click="scollClick">
              <img src="../assets/index/Group.png" alt="" />
            </div>
          </div>
          <div class="number">
            <span>{{ te }}</span>
            <span>/</span>
            <span>6</span>
          </div>
        </div>
      </div>
      <!-- 二 -->
      <div class="section" id="section2" ref="view">
        <div class="two">
          <div
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              z-index: 99;
            "
          >
            <heade :routePath="routePath" :fullIndex="fullIndex" @changeHome="changeHome"></heade>
          </div>
          <div class="intorduction">
            <div class="intorLeft">
              <div class="intorIamge">
                <img src="../assets/index/Introduction.png" alt="" />
              </div>
              <div class="brief">
                <p>
                  {{ $t("homeView.tion")
                  }}<span>{{ $t("homeView.brief") }}</span>
                </p>
              </div>
              <div class="text wow fadeInUp" data-wow-delay="0.2s">
                {{ $t("birefDetail") }}
              </div>
              <div class="look" @click="lookBiref">
                <span
                  >{{ $t("lookText") }}<i class="iconfont">&#xe600;</i></span
                >
              </div>
            </div>
            <div class="intorRight">
              <img src="../assets/index/intorImg.png" alt="" />
            </div>
          </div>
        </div>
        <div class="botImage"></div>
      </div>
      <!-- 三 -->
      <div class="section page3">
        <div class="productBox">
          <div
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              z-index: 99;
            "
          >
            <heade :routePath="routePath" :fullIndex="fullIndex" @changeHome="changeHome"></heade>
          </div>
          <div class="product">
            <div class="productImage">
              <img src="../assets/index/product.png" alt="" />
            </div>
            <div class="productTitle">
              <p>{{ $t("productText") }}</p>
            </div>
          </div>
          <div class="purper">
            <div class="purperLeft">
              <ul>
                <li
                  :class="currentIndex == index ? 'current' : ''"
                  v-for="(item, index) in purper"
                  :key="index"
                >
                  <div class="flexB">
                    <div class="flexI">
                      <div class="purperTitle">
                        <div class="purperImage">
                          <img :src="item.image" alt="" />
                        </div>
                        <div class="purpername">{{ item.title }}</div>
                      </div>
                      <div class="quality">
                        <h3>{{ item.policy }}</h3>
                        <p>{{ item.text1 }}</p>
                        <p>{{ item.text2 }}</p>
                        <p>{{ item.text3 }}</p>
                        <p>{{ item.text4 }}</p>
                      </div>
                      <div class="look purperLook" @click="purperClick">
                        <span
                          >{{ $t("lookText")
                          }}<i class="iconfont">&#xe600;</i></span
                        >
                      </div>
                    </div>
                    <div class="quImage">
                      <img :src="item.productImage" alt="" />
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="tabImage">
              <ul>
                <li
                  @click="change(index)"
                  :class="currentIndex == index ? 'bgColor' : ''"
                  v-for="(item, index) in tab"
                  :key="index"
                >
                  <div class="tabIcon">
                    <i class="iconfont" v-html="item.icon"></i>
                    <span>{{ item.iconText }}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- 四 -->
      <div class="section">
        <div class="three">
          <div
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              z-index: 99;
            "
          >
            <heade :routePath="routePath" :fullIndex="fullIndex" @changeHome="changeHome"></heade>
          </div>
          <div class="technology">
            <div class="techTitle">
              <div class="techImage">
                <img src="../assets/index/Technology.png" alt="" />
              </div>
              <div class="prise">
                <p>
                  {{ $t("tech") }}<span> {{ $t("technologyed") }}</span>
                </p>
              </div>
              <h3>{{ $t("technologyText") }}</h3>
            </div>
            <div class="frimy">
              <div class="frimyI">
                <ul class="stateIndex">
                  <li
                    v-for="(item, index) in technology"
                    :key="index"
                    :class="{ staStyle: listIndex === index }"
                  >
                    <div class="frimyImage">
                      <img :src="item.frimyImage" alt="" />
                    </div>
                    <p class="frimyText">{{ item.frimyText }}</p>
                    <div class="frimyBrief">
                      <span></span>
                      <p>{{ item.frimyBrief }}</p>
                    </div>
                  </li>
                </ul>
                <ul class="patentU">
                  <li
                    v-for="(item, index) in patentBox"
                    :key="index"
                    :class="{ backBeijing: listIndex === index }"
                    v-on:mouseout="removeActive(index)"
                    v-on:mouseover="changeActive(index)"
                  >
                    <h3 class="patent">{{ item.patentH }}</h3>
                    <div class="patentBrief">
                      <span></span>
                      <p>{{ item.patentBrief }}</p>
                    </div>
                    <div class="patentD" @click="patentLook(item)">
                      <span
                        >{{ $t("lookView")
                        }}<i class="iconfont">&#xe600;</i></span
                      >
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 五 -->
      <div class="section">
        <div class="four">
          <div
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              z-index: 99;
            "
          >
            <heade :routePath="routePath" :fullIndex="fullIndex" @changeHome="changeHome"></heade>
          </div>
          <div class="newBox">
            <div class="newTitle">
              <img src="../assets/index/news.png" alt="" />
            </div>
            <div class="tabtitle">
              <p>
                {{ $t("homeView.nw")
                }}<span>{{ $t("homeView.xw") }}</span>
              </p>
            </div>
            <div class="newText">
              <div class="newLeft">
                <img src="../assets/index/newLeft.png" alt="" />
              </div>
              <div class="newRight">
                <ul>
                  <li
                    v-for="(item, index) in newH"
                    :key="index"
                    @click="detailClick(item)"
                  >
                    <div class="newTime">
                      <p>
                        <span>{{ item.day }}</span>
                        <span>{{ item.ym }}</span>
                      </p>
                    </div>
                    <div class="newBull">
                      <h3 v-if="yuyinIn === 'ZH'">{{ item.title }}</h3>
                      <h3 v-else>{{ item.title_en }}</h3>
                      <p v-if="yuyinIn === 'ZH'">{{ item.sub_title }}</p>
                      <p v-else>{{ item.sub_title_en }}</p>
                    </div>
                    <div class="bar"></div>
                  </li>
                </ul>
                <div class="look lookB" @click="newClick">
                  <span
                    >{{ $t("lookText") }}<i class="iconfont">&#xe600;</i></span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section fp-auto-height">
        <div>
          <foot></foot>
        </div>
      </div>
    </full-page>
  </div>
</template>

<script>
import heade from "../components/header.vue";
import foot from "../components/footer.vue";
import $ from "jquery";
import axios from "../utils/request.js";
export default {
  name: "",
  data() {
    return {
      listIndex: "",
      newH: [], //新闻
      te: 1,
      currentIndex: 0,
      fullIndex: 0,
      options: {
        licenseKey: "OPEN-SOURCE-GPLV3-LICENSE",
        scrollOverflow: true,
        menu: "#menu",
        fitToSection: true,
        navigation: true,
        navigationPosition: "right",
        navigationTooltips: [],
        showActiveTooltip: true, //是否显示当前页面的tooltip信息
        verticalCentered: true, //垂直居中
        afterLoad: this.afterLoad,
      },
      routePath: this.$route.path, //接收header父组件
      fullIndex: this.$route.path,
      yuyinIn: "",
    };
  },
  computed: {
    avigationData() {
      return [
        this.$t("navigator.navIndex") + "——",
        this.$t("navigator.navBiref") + "——",
        this.$t("navigator.navPro") + "——",
        this.$t("navigator.navtec") + "——",
        this.$t("navigator.navNew") + "——",
        this.$t("navigator.navDao") + "——",
      ];
    },
    images() {
      return [
        // {
        //   url: require("../assets/index/banner.png"),
        //   text: '',
        //   textwo: '',
        // },
        {
          url: require("../assets/index/banner.png"),
          text: this.$t("images.down"),
          textwo: this.$t("images.downwo"),
        },
        {
          id: "2",
          url: require("../assets/index/banner.png"),                     
          value: this.$t("images.value"),
          valuebold: this.$t("images.valuebold"),
          make: this.$t("images.make"),
          makebold: this.$t("images.makebold"),
          wider: this.$t("images.wider"),
          widerbold: this.$t("images.widerbold"),
        },
        {
          url: require("../assets/index/banner.png"),
          text: this.$t("images.ability"),
          textwo: this.$t("images.abilitytwo"),
        },
        {
          url: require("../assets/index/banner.png"),
          text: this.$t("images.limit"),
          textwo: this.$t("images.limitwo"),
        },
        {
          url: require("../assets/index/banner.png"),
          text: this.$t("images.sine"),
          textwo: this.$t("images.sinetwo"),
        },
        {
          url: require("../assets/index/banner.png"),
          text: this.$t("images.dusty"),
          textwo: this.$t("images.dustytwo"),
        },
      ];
    },
    purper() {
      return [
        {
          image: require("../assets/index/purper1.png"),
          title: this.$t("purper.tubeTitle"),
          policy: this.$t("purper.tubePolicy"),
          text1: this.$t("purper.rawText"),
          text2: this.$t("purper.equText"),
          text3: this.$t("purper.runText"),
          text4: this.$t("purper.manText"),
          productImage: require("../assets/index/product1.png"),
        },
        {
          image: require("../assets/index/purper4.png"),
          title: this.$t("purper.tubeTitles"),
          policy: this.$t("purper.tubePolicy"),
          text1: this.$t("purper.rawText"),
          text2: this.$t("purper.equText"),
          text3: this.$t("purper.runText"),
          text4: this.$t("purper.manText"),
          productImage: require("../assets/index/product4.png"),
        },
        {
          image: require("../assets/index/purper2.png"),
          title: this.$t("purper.tubeTitless"),
          policy: this.$t("purper.tubePolicy"),
          text1: this.$t("purper.rawText"),
          text2: this.$t("purper.equText"),
          text3: this.$t("purper.runText"),
          text4: this.$t("purper.manText"),
          productImage: require("../assets/index/product2.png"),
        },
        {
          image: require("../assets/index/purper3.png"),
          title: this.$t("purper.tubeTitlesss"),
          policy: this.$t("purper.tubePolicy"),
          text1: this.$t("purper.rawText"),
          text2: this.$t("purper.equText"),
          text3: this.$t("purper.runText"),
          text4: this.$t("purper.manText"),
          productImage: require("../assets/index/product3.png"),
        },
      ];
    },
    tab() {
      return [
        {
          icon: "&#xe675;",
          iconText: this.$t("tab.iconText"),
        },
        {
          icon: "&#xe676;",
          iconText: this.$t("tab.iconText1"),
        },
        {
          icon: "&#xe674;",
          iconText: this.$t("tab.iconText2"),
        },
        {
          icon: "&#xe673;",
          iconText: this.$t("tab.iconText3"),
        },
      ];
    },
    technology() {
      return [
        {
          frimyImage: require("../assets/index/tech4.png"),
          frimyText: this.$t("technology.firmyText"),
          frimyBrief: this.$t("technology.frimyBrief"),
        },
        {
          frimyImage: require("../assets/index/tech2.png"),
          frimyText: this.$t("technology.frimyText2"),
          frimyBrief: this.$t("technology.frimyBrief2"),
        },
        {
          frimyImage: require("../assets/index/tech1.png"),
          frimyText: this.$t("technology.frimyText3"),
          frimyBrief: this.$t("technology.frimyBrief3"),
        },
        {
          frimyImage: require("../assets/index/tech3.png"),
          frimyText: this.$t("technology.frimyText4"),
          frimyBrief: this.$t("technology.frimyBrief4"),
        },
      ];
    },
    patentBox() {
      return [
        {
          patentH: this.$t("patentBox.patent1"),
          patentBrief: this.$t("patentBox.patentBrief1"),
          id:'3'
        },
        {
          patentH: this.$t("patentBox.patent2"),
          patentBrief: this.$t("patentBox.patentBrief2"),
          id:'3'
        },
        {
          patentH: this.$t("patentBox.patent3"),
          patentBrief: this.$t("patentBox.patentBrief3"),
          id:'3'
        },
        {
          patentH: this.$t("patentBox.patent4"),
          patentBrief: this.$t("patentBox.patentBrief4"),
          id:'3'
        },
      ];
    },
  },
  mounted() {
    this.yuyinIn = localStorage.getItem("languageSet") || "ZH";
    this.options.navigationTooltips = this.avigationData;
    this.getChart();
    this.getNews();
    if (this._isMobile()) {
      this.$router.push({
        path: "/phoneIndex",
      });
    } else {
    }
  },
  methods: {
    changeActive(index) {
      this.listIndex = index;
    },
    removeActive() {
      this.listIndex = -1;
    },
    changeHome(e) {
      if (e) {
        this.yuyinIn = e;
        this.options.navigationTooltips = this.avigationData;
        // this.getNews();
      }
    },
    scollClick() {
      this.$refs.fullpage.api.moveSectionDown();
    },
    // 轮播图
    getChart() {
      axios
        .get("/index/banner")
        .then((res) => {
          this.images[0].url = res.data[0].image;
          this.images[1].url = res.data[1].image;
          this.images[2].url = res.data[2].image;
          this.images[3].url = res.data[3].image;
          this.images[4].url = res.data[4].image;
          this.images[5].url = res.data[5].image;
          this.images[6].url = res.data[6].image;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 新闻
    getNews() {
      // this.yuyinIn = localStorage.getItem('languageSet') || ;
      axios
        .get("/index/article", {
          params: {
            limit: "3",
            page: "1",
            cate_id: 102
          },
        })
        .then((res) => {
          this.newH = res.data.list;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 轮播图左下轮播
    changeData(e) {
      this.te = e + 1;
    },
    // 判断手机端
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    change: function (index) {
      this.currentIndex = index;
    },
    afterLoad(origin, destination, direction) {
      if (destination.index == 4) {
        this.getNews();
      }
      this.fullIndex = destination.index;
      if (this.fullIndex == 1 || this.fullIndex == 3 || this.fullIndex == 4) {
        $(".head").css("box-shadow", "0px 8px 12px rgba(0, 0, 0, 0.06)");
      } else if (this.fullIndex == 0 || this.fullIndex == 2) {
        $(".head").css("box-shadow", "0px 8px 12px rgba(0, 0, 0, 0)");
      }
      if (destination.index == 0 || destination.index == 2) {
        $("#fp-nav ul li a span:nth-child(2)").css("background", "#fff");
        $("#fp-nav ul li .fp-tooltip").css("color", "#fff");
      } else if (
        destination.index == 1 ||
        destination.index == 3 ||
        destination.index == 4 ||
        destination.index == 5
      ) {
        $("#fp-nav ul li a span:nth-child(2)").css("background", "#709C12");
        $("#fp-nav ul li .fp-tooltip").css("color", "#709c12");
      }
    },
    lookBiref() {
      this.$router.push({
        path: "/goPage",
      });
    },
    patentLook(item) {
      this.$router.push({
        path: "/goPage",
        query: {
          id: item.id,
        },
      });
    },
    newClick() {
      this.$router.push({
        path: "/news",
      });
    },
    detailClick(item) {
      this.$emit("father",item.id)
      this.$router.push({
        path: "/newsDetail",
        query: {
          id: item.id,
        },
      });
    },
    purperClick() {
      this.$router.push({
        path: "/product",
      });
    },
  },
  components: {
    heade,
    foot,
  },
};
</script>

<style scoped lang="stylus">
img {
  max-width: 100%;
  display: block;
}

li {
  list-style: none;
}

#fp-nav ul li a /deep/ span:nth-child(2) {
  background: #aaa !important;
}

/deep/ #fp-nav ul li .fp-tooltip {
  top: 0.01rem;
  left: -0.52rem;
}

/deep/.el-carousel__indicators--outside {
  position: absolute !important;
  bottom: 0 !important;
}

/deep/.el-carousel__indicators--outside button {
  background-color: #ffffff !important;
}

/deep/.el-carousel__indicators--horizontal {
  left: 0 !important;
}

/deep/.el-carousel ul {
  background: #709c12;
  width: 6.01rem;
  height: 1.06rem;
  line-height: 1.2rem;
}

/deep/.el-carousel ul li {
  padding: 0.12rem 0.12rem !important;
}

/deep/.el-carousel ul li.is-active {
  border-radius: 50px;
  border: 1px solid #fff;
}

/deep/.el-carousel__button {
  width: 0.08rem !important;
  height: 0.08rem !important;
  border-radius: 50px;
}

/deep/ .el-carousel__item.is-animating {
  transition: transform 0.8s ease-in-out !important;
}

.banner {
  height: 100vh;
}

.down, .earth {
  z-index: 999999;
  position: absolute;
  top: 38%;
  left: 2.4rem;
  width: 12rem;
}

.down span, .earth p, .earth p span {
  color: #fff;
  font-size: 0.64rem;
  letter-spacing: 0.04em;
  font-weight: 300;
}

.down span:nth-child(1) {
  font-weight: 900;
  padding-right: 0.4rem;
}

.earth p span {
  font-weight: 900;
}

.cusor {
  position: absolute;
  bottom: 1.6rem;
  z-index: 9;
  left: 50%;
}

.cusorIamge {
  width: 0.24rem;
  height: 0.42rem;
  cursor: pointer;
}

.cusorIamge img {
  width: 100%;
  height: 100%;
}

@keyframes move {
  25% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }

  50% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  75% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.cusor {
  animation: move 1.5s infinite forwards;
  -webkit-animation: move 1.5s infinite forwards;
  animation: move 1.5s infinite forwards;
}

.number {
  position: absolute;
  bottom: 0;
  left: 31.3%;
  z-index: 9;
  width: 1.92rem;
  height: 1.06rem;
  line-height: 1.06rem;
  text-align: center;
  background: #fff;
}

.number span:nth-child(1) {
  color: #709c12;
  font-size: 0.3rem;
  font-weight: 400;
}

.number span:nth-child(2), .number span:nth-child(3) {
  color: #666666;
  font-size: 0.26rem;
}

/* page2 */
.intorduction {
  padding: 1.8rem 2.4rem 0;
  display: flex;
  justify-content: space-between;
}

.botImage {
  width: 100%;
  height: 1.17rem;
  background-image: url('../assets/index/intro.png');
  background-size: 100% 100%;
}

.intorLeft .intorIamge {
  width: 3.77rem;
  height: 0.5rem;
}

.intorIamge img {
  width: 100%;
  height: auto;
  display: block;
}

.brief {
  margin: 0.08rem 0 0.56rem;
  width: max-content;
  height: 0.6rem;
  line-height: 0.6rem;
  background-image: url('../assets/index/brief.png');
  background-size: 100% 100%;
}

.brief p {
  color: #131313;
  font-size: 0.4rem;
  font-weight: 700;
  margin: 0;
}

.brief p span {
  color: #709c12;
}

.text {
  width: 5.6rem;
  color: #888888;
  font-size: 0.16rem;
  font-weight: 400;
  line-height: 0.32rem;
  text-align: justify;
}

.look {
  width: 1.6rem;
  height: 0.5rem;
  line-height: 0.5rem;
  margin-top: 1.2rem;
  text-align: center;
  cursor: pointer;
}

.look span {
  display: block;
  color: #709c12;
  font-size: 0.18rem;
  font-weight: 400;
  border: 1px solid #709c12;
  border-radius: 0px 0px 0.12rem 0px;
  transition: all 0.3s;
}

.look:hover span {
  color: #ffffff;
  background: #709c12;
}

.look span i {
  padding-left: 0.16rem;
}

.intorRight {
  width: 7.43rem;
  height: 6.79rem;
  overflow: hidden;
}

.intorRight img {
  transition: all 0.8s ease-out;
}

.intorRight img:hover {
  transform: scale(0.9);
}

/* page3 */
.page3 {
  width: 100%;
  height: 100vh;
  background-image: url('../assets/index/page3.png');
  background-size: 100% 100%;
}

.productTitle {
  width: max-content;
  height: 0.6rem;
  line-height: 0.6rem;
  background-image: url('../assets/index/productB.png');
  background-size: 100% 100%;
}

.productImage {
  width: 2.44rem;
  height: 0.5rem;
  margin: 0 auto;
  padding: 1.2rem 0 0.08rem;
}

.productImage img {
  width: 100%;
  height: auto;
  display: block;
}

.productTitle {
  margin: 0 auto;
}

.productTitle p {
  margin: 0;
  color: #fff;
  font-size: 0.4rem;
  font-weight: 700;
  text-align: center;
}

ul, p, h3 {
  margin: 0;
  padding: 0;
}

.purperLeft ul li {
  display: none;
}

.purperLeft ul li .flexB {
  display: flex;
  justify-content: space-between;
}

.purperLeft ul li.current {
  display: block;
}

.bgColor {
  background-color: #fff;
  border-bottom-right-radius: 0.08rem;
}

.bgColor .tabIcon i {
  color: #709c12;
}

.bgColor .tabIcon span {
  color: #709c12;
}

.tabImage ul li:hover .tabIcon i {
  color: #709c12;
}

.tabImage ul li:hover .tabIcon span {
  color: #709c12;
}

.tabImage ul li:hover {
  background: #ffffff;
}

.purper {
  padding: 0 2.4rem;
}

.purperImage {
  width: 0.81rem;
  height: 0.81rem;
}

.purperImage img {
  width: 100%;
  height: 100%;
  display: block;
}

.purperTitle {
  display: flex;
  align-items: center;
}

.purperTitle .purpername {
  font-weight: 500;
  font-size: 0.2rem;
  text-align: justify;
  color: #ffffff;
  padding-left: 0.32rem;
}

.quality {
  margin-top: 0.46rem;
}

.quality h3 {
  color: #fff;
  font-size: 0.3rem;
  font-weight: 400;
}

.quality p {
  color: #ffffff;
  font-size: 0.2rem;
  padding-top: 0.2rem;
  font-weight: 200;
}

.purperLook {
  margin-top: 0.5rem;
}

.purperLook span {
  color: #fff;
  border: 0.01rem solid #fff;
}

.purperLook:hover span {
  background: #fff;
  color: #709c12;
}

.quImage {
  width: 4.73rem;
  height: 4.73rem;
}

.quImage img {
  height: 100%;
}

.tabImage {
  padding: 0 3.84rem;
}

.tabImage ul {
  display: flex;
  justify-content: space-between;
}

.tabImage ul li {
  font-size: 0;
  cursor: pointer;
  transition: all 0.5s;
}

.tabIcon {
  width: 1.2rem;
  height: 1.2rem;
  border: 0.851649px solid #ffffff;
  border-bottom-right-radius: 8px;
  text-align: center;
}

.tabIcon i {
  font-size: 0.4rem;
  color: #fff;
  margin: 0.2rem 0 0.06rem;
  display: block;
  transition: all 0.5s;
}

.tabIcon span {
  color: #ffffff;
  font-size: 0.16rem;
  font-weight: 400;
  display: block;
  transition: all 0.5s;
}

/* page4 */
.technology {
  background: #f6f6f6;
  height: 100vh;
}

.techTitle {
  text-align: center;
}

.techImage {
  width: 3.55rem;
  height: 0.5rem;
  margin: 0 auto;
  padding: 1.2rem 0 0 0;
  z-index: 99;
}

.prise, .tabtitle {
  width: max-content;
  height: 0.6rem;
  background-image: url('../assets/index/brief.png');
  background-size: 100% 100%;
  margin: 0 auto;
  z-index: 99;
}

.prise p, .tabtitle p {
  color: #131313;
  font-size: 0.4rem;
  font-weight: 700;
}

.prise p span, .tabtitle p span {
  color: #709c12;
}

.techTitle h3 {
  color: #333333;
  font-size: 0.18rem;
  font-weight: 400;
  padding-top: 0.14rem;
}

.frimy {
  padding: 0.2rem 2.4rem 0;
}

.frimy ul {
  display: flex;
  justify-content: space-between;
}

// .stateIndex li {
// opacity: 1;
// }
.frimyImage {
  width: 0.56rem;
  height: 0.56rem;
  padding: 0.6rem 0.4rem 0;
}
.stateIndex li {
  width: 3.6rem;
}
.frimyText {
  color: #333333;
  font-size: 0.22rem;
  font-weight: 700;
  width: 2.8rem;
  height: 1.8rem;
  line-height: 0.36rem;
  font-family: none;
  font-style: normal;
  padding: 0.32rem 0.4rem 0;
}

.frimyBrief {
  width: 2.8rem;
  padding: 0 0.4rem;
}

.frimyBrief span {
  width: 0.32rem;
  height: 0.08rem;
  background: #709c12;
  display: block;
  margin-bottom: 0.16rem;
}

.frimyBrief p {
  color: #999999;
  font-size: 0.14rem;
  font-weight: 400;
  line-height: 0.28rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
}

.frimyI {
  position: relative;
}

.patentU {
  position: absolute;
  top: 0;
  z-index: 99;
}

.patentU li {
  width: 3.6rem;
  height: 6.6rem;
  opacity: 0;
}

.patentU li:hover {
}

.staStyle {
  opacity: 0;
}

.backBeijing {
  opacity: 1 !important;
  background: url('@/assets/index/techTab.png') no-repeat;
  background-size: 100% 100%;
  transition: all 0.6s ease-in-out;
}

.patentU li .patent {
  color: #fff;
  font-size: 0.24rem;
  font-weight: 700;
  height: 1.8rem;
  padding-top: 0.49rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
}
.patentBrief {
  padding: 0.4rem 0.4rem 0!important;
  height: 2.1rem;
}
.patentBrief span {
  width: 0.32rem;
  height: 0.08rem;
  background: #fff;
  display: block;
}

.patentBrief p {
  color: #fff;
  font-size: 0.14rem;
  font-weight: 400;
  line-height: 0.28rem;
  margin-top: 0.16rem;
}

.patentD, .newD {
  width: 1.6rem;
  height: 0.5rem;
  line-height: 0.5rem;
  margin-top: 0.4rem;
  text-align: center;
  cursor: pointer;
}
.newD:hover span, .patentD:hover span {
  color: #709c12;
  background: #fff;
  border: 0.01rem solid #709c12;
  transition: all 0.3s ease-in-out;
}

.newD:hover span i, .patentD:hover span i {
  color: #709c12;
}

.patentD span, .newD span {
  display: block;
  color: #fff;
  font-size: 0.18rem;
  font-weight: 400;
  border: 0.01rem solid #fff;
  border-radius: 0px 0px 0.12rem 0px;
  transition: all 0.3s;
}

.patentD span i, .newD span i {
  color: #fff;
  padding-left: 0.16rem;
  transition: all 0.3s ease-in-out;
}

.patent, .patentBrief, .patentD {
  padding: 0 0.4rem;
}

/* 五 */
.newTitle {
  width: 1.75rem;
  height: 0.5rem;
  margin: 0 auto;
  padding-top: 1.2rem;
}

.newText {
  display: flex;
  justify-content: space-between;
  padding: 0.7rem 2.4rem 0;
}

.newLeft {
  width: 7.2rem;
  height: 5.4rem;
  overflow: hidden;
  border-radius: 0.32rem 0;
}

.newLeft img {
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 0.32rem 0;
  transition: all 0.5s ease-out;
}

.newLeft img:hover {
  transform: scale(1.2);
}

.newRight li {
  width: 6.72rem;
  display: flex;
  align-items: center;
  padding-bottom: 0.4rem;
  margin-bottom: 0.4rem;
  border-bottom: 0.01rem solid #ececec;
  cursor: pointer;
  position: relative;
}

.newRight li .bar {
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0.01rem;
  background: #709c12;
  transition: all 0.8s;
}

.newRight li:hover .newTime span:nth-child(1), .newRight li:hover .newBull h3 {
  color: #709c12;
}

.newRight li:hover .bar {
  width: 5.3rem;
}

.newRight li .newTime {
  width: 0.7rem;
  text-align: center;
  padding-right: 0.32rem;
  font-size: 0;
}

.newTime p span:nth-child(1) {
  color: #333333;
  font-size: 0.32rem;
  font-weight: 700;
  transition: all 0.3s;
}

.newTime p span:nth-child(2) {
  display: block;
  color: #666;
  font-size: 0.16rem;
  font-weight: 400;
}

.newBull h3 {
  color: #333333;
  font-size: 0.2rem;
  font-weight: 500;
  line-height: 0.3rem;
  transition: all 0.3s;
  width: 5rem;
}

.newBull p {
  width: 5.26rem;
  color: #888888;
  font-size: 0.16rem;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-top: 0.08rem;
}

.lookB {
  margin-top: 0.6rem;
}
</style>