<template>
  <div class="loding-page">
    <div class="logoTitle">
      <div class="logo">
        <img src="../assets/index/logo.png" alt="" />
      </div>
    </div>
    <div class="lotte">
      <div class="logo-num">
        <countTo
          ref="exampleCount"
          :startVal="startVal"
          :endVal="endVal"
          :duration="duration"
          :autoplay="false"
        >
        </countTo
        >%
      </div>
      <div id="lottie"></div>
    </div>
  </div>
</template>
<script>
import countTo from "vue-count-to";
import lottie from "lottie-web";
import animationData from "../assets/data.json";
export default {
  props: ["loadingShow"],
  name: "loading",
  data() {
    return {
      autoplay: false,
      // 需要滚动的时间
      duration: 3000,
      // 初始值
      startVal: 0,
      // 最终值
      endVal: 100,
    };
  },
  mounted() {
    this.svgFun();
    setTimeout(() => {
      this.$refs.exampleCount.start();
    }, 1000);
    setTimeout(() => {
      this.$router.push("/HomeView");
    }, 3200);
  },
  methods: {
    svgFun() {
      let params = {
        container: document.getElementById("lottie"),
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: animationData,
      };
      let anim;
      anim = lottie.loadAnimation(params);
    },
  },
  components: {
    countTo,
    lottie,
  },
};
</script>

<style>
body {
  margin: 0;
  font-size: 0;
}
.loadingHidden {
  animation: opacityAni 2s ease 0s 1 forwards;
  -moz-animation: opacityAni 2s ease 0s 1 forwards; /* Firefox */
  -webkit-animation: opacityAni 2s ease 0s 1 forwards; /* Safari and Chrome */
  -o-animation: opacityAni 2s ease 0s 1 forwards; /* Opera */
}
.loading-page {
  background: #0949da;
  background-size: 100% 100%;
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 99999999;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.logoTitle {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.logo {
  width: 2.6rem;
  height: 1.04rem;
}
.logo image {
  width: 100%;
  height: 100%;
}
.lotte {
  position: absolute;
  bottom: 0;
}
.logo-num {
  position: absolute;
  z-index: 999;
  top: 60%;
  left: 50%;
  color: #fff;
  font-size: 0.32rem;
}
</style>
