<template>
  <div>
    <div class="head">
      <div class="log">
        <div v-if="newRottePath == '/HomeView'">
          <div class="logoImg" @click="logoClick">
            <img src="../assets/header/logo.png" alt="" />
          </div>
        </div>
        <div v-else>
          <div class="logoImg" @click="logoClick">
            <img src="../assets/header/logoW.png" alt="" />
          </div>
        </div>
        <div class="headRight" v-if="newRottePath == '/HomeView'">
          <div
            class="active"
            v-for="(item, index) in flarr"
            :key="index"
            :class="{ activess: everyid == index }"
          >
            <span @click="link(index)">
              <div class="actived">
                {{ item.name }}
                <div v-if="everyid == index" class="redx"></div>
              </div>
            </span>
          </div>
          <div>
            <span @click="changeLanguage()">
              <div class="actived">{{ yuyinIn==='ZH'?'EN':'CN' }}</div>
            </span>
          </div>
          <!-- <div class="vertical"></div> -->
          <div class="switch">
            <div class="switchImage" @click="clickOpen()">
              <img src="../assets/header/switch.png" alt="" />
            </div>
          </div>
        </div>
        <div class="headRights" v-else>
          <div
            class="active"
            v-for="(item, index) in flarr"
            :key="index"
            :class="{ activessS: everyid == index }"
          >
            <span @click="link(index)">
              <div class="actived">
                {{ item.name }}
                <div v-if="everyid == index" class="redx"></div>
              </div>
            </span>
          </div>
          <div>
            <span @click="changeLanguage()">
              <div class="actived">{{ yuyinIn==='ZH'?'EN':'CN' }}</div>
            </span>
          </div>
          <!-- <div class="vertical"></div> -->
          <div class="switch">
            <div class="switchImage" @click="clickOpen()">
              <img src="../assets/header/switchW.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <!-- 切换 -->
      <div class="tabindex" :class="{ change: every }">
        <div class="huisheng">
          <a href="/">
            <div class="logoImg">
              <img src="../assets/header/logo.png" alt="" />
            </div>
          </a>
          <div class="huiImage" @click="clickClose()">
            <img src="../assets/header/cross.png" alt="" />
          </div>
        </div>
        <div class="briefBox" v-if="every">
          <ul>
            <li>
              <thead @click="page">
                {{ gopage }}
              </thead>
              <tr
                v-for="(item, index) in pageB"
                :key="index"
                @click="pageClick(item)"
              >
                <td>{{ item.text }}</td>
              </tr>
            </li>
            <li>
              <thead @click="product">
                {{ productText }}
              </thead>
              <tr
                v-for="(item, index) in productB"
                :key="index"
                @click="productClick(item)"
              >
                <td>{{ item.text }}</td>
              </tr>
            </li>
            <li>
              <thead @click="newClick">
                {{ newText }}
              </thead>
              <tr>
                <td @click="newClick">{{ newText }}</td>
              </tr>
              <tr>
                <td @click="cityClick">{{ cityText }}</td>
              </tr>
            </li>
            <li>
              <thead @click="group">
                {{ groupText }}
              </thead>
              <tr
                v-for="(item, index) in groupB"
                :key="index"
                @click="groupClick(item)"
              >
                <td>{{ item.text }}</td>
              </tr>
            </li>
            <li>
              <thead @click="contactClick">
                {{ contactText }}
              </thead>
              <tr @click="contactClick">
                <td>{{ contactText }}</td>
              </tr>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    routePath: "",
    fullIndex: "",
  },
  data() {
    return {
      tabIndex: 0,
      every: false,
      everyid: -1,
      newRottePath: this.routePath,
      yuyinIn: "",
    };
  },
  computed: {
    flarr() {
      return [
        { name: this.$t("HeadersData.shouye"), page: "/HomeView" },
        { name: this.$t("HeadersData.zoujin"), page: "/goPage" },
        { name: this.$t("HeadersData.chanpin"), page: "/product" },
        { name: this.$t("HeadersData.xinwen"), page: "/news" },
        { name: this.$t("HeadersData.jituan"), page: "/group" },
        { name: this.$t("HeadersData.lianxi"), page: "/contact" },
      ];
    },
    gopage() {
      return this.$t("gopage")
    },
    pageB() {
      return [
        {
          text: this.$t("pageB.birefText"),
          id: "1",
        },
        {
          text: this.$t("pageB.cultureText"),
          id: "2",
        },
        {
          text: this.$t("pageB.techText"),
          id: "3",
        },
        {
          text: this.$t("pageB.honorText"),
          id: "4",
        },
      ]
    },
    productText() {
      return this.$t("product")
    },
    productB() {
      return [
        {
          text: this.$t("productB.paperText"),
          id: "1",
        },
        {
          text: this.$t("productB.tubeText"),
          id: "2",
        },
        {
          text: this.$t("productB.cardText"),
          id: "3",
        },
        {
          text: this.$t("productB.mold"),
          id: "4",
        },
      ]
    },
    newText() {
      return this.$t("newText")
    },
    cityText() {
      return this.$t("cityText")
    },
    groupText() {
      return this.$t("groupText")
    },
    groupB() {
      return [
        {
          text: this.$t("groupB.shares"),
          id: "1",
        },
        {
          text: this.$t("groupB.plain"),
          id: "2",
        },
        {
          text: this.$t("groupB.sulation"),
          id: "3",
        },
        {
          text: this.$t("groupB.sales"),
          id: "4",
        },
        {
          text: this.$t("groupB.pack"),
          id: "5",
        },
      ]
    },
    contactText() {
      return this.$t("contactText")
    }
  },
  watch: {
    fullIndex: {
      handler(newValue, oldValue) {
        if (newValue == 0 || newValue == 2) {
          this.newRottePath = "/HomeView";
          this.yuyinIn = localStorage.getItem("languageSet") || "ZH";
        } else if (newValue == 1 || newValue == 3 || newValue == 4) {
          this.newRottePath = "/goPage";
          this.yuyinIn = localStorage.getItem("languageSet") || "ZH";
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.yuyinIn = localStorage.getItem("languageSet") || "EN";
    if (this.newRottePath == "/goPage") {
      this.everyid = 1;
    } else if (this.newRottePath == "/HomeView") {
      this.everyid = 0;
    } else if (this.newRottePath == "/product") {
      this.everyid = 2;
    } else if (this.newRottePath == "/news") {
      this.everyid = 3;
    } else if (this.newRottePath == "/group") {
      this.everyid = 4;
    } else if (this.newRottePath == "/contact") {
      this.everyid = 5;
    } else if (this.newRottePath == "/newsDetail") {
      this.everyid = 3;
    }
  },
  methods: {
    changeLanguage() {
      this.$i18n.locale == "ZH" ? (this.$i18n.locale = "EN") : (this.$i18n.locale = "ZH"); //设置中英文模式
      localStorage.setItem("languageSet", this.$i18n.locale);
      this.yuyinIn = localStorage.getItem("languageSet");
      if (this.newRottePath === "/news") {
        this.$emit("cahngeData", this.yuyinIn);
      } else if (this.newRottePath === "/contact") {
        this.$emit("changeContact", this.yuyinIn);
      } else if (this.newRottePath === "/goPage") {
        this.$emit("changeHome",this.yuyinIn);
        this.$emit("cahngeData",this.yuyinIn);
      } else if(this.newRottePath === "/HomeView") {
        this.$emit("changeHome",this.yuyinIn);
      } else if(this.newRottePath === "/newsDetail") {
        this.$emit("changeContact",this.yuyinIn);
      } else if(this.newRottePath === "/city") {
        this.$emit("cahngeData",this.yuyinIn);
      }
    },
    clickOpen() {
      this.every = true;
    },
    clickClose() {
      this.every = false;
    },
    link(index) {
      this.everyid = index;
      this.$router.push({
        path: this.flarr[index].page,
      });
    },
    page() {
      this.$router.push({
        path: "/goPage",
      });
    },
    pageClick(item) {
      this.$emit("father",item.id)
      this.$router.push({
        path: "/goPage",
        query: {
          id: item.id,
        },
      });
    },
    product() {
      this.$router.push({
        path: "/product",
      });
    },
    productClick(item) {
      this.$emit("father",item.id)
      this.$router.push({
        path: "/product",
        query: {
          id: item.id,
        },
      });
    },
    newClick() {
      this.$router.push({
        path: "/news",
      });
    },
    cityClick() {
      this.$router.push({
        path: "/city",
        query: {
          cate_id: 103
        }
      });
    },
    group() {
      this.$router.push({
        path: "/group",
      });
    },
    groupClick(item) {
      this.$emit("father",item.id)
      this.$router.push({
        path: "/group",
        query: {
          id: item.id,
        },
      });
    },
    contactClick() {
      this.$router.push({
        path: "/contact",
      });
    },
    logoClick() {
      this.$router.push({
        path: "/HomeView",
      });
    }
  },
};
</script>


<style scoped>
ul,
p,
h3 {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
  display: block;
}

li {
  list-style: none;
}

p {
  font-size: 0;
}
.head {
  width: 100%;
  z-index: 9;
}
.log {
  padding: 0 2.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 0.9rem;
}
.logoImg {
  width: 1.2rem;
  height: 0.48rem;
}
.logoImg img {
  width: 100%;
  height: 100%;
  display: block;
}
.headRight {
  display: flex;
  padding-top: 0.13rem;
  position: relative;
}
.headRight div {
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.16rem;
  margin-left: 0.28rem;
  cursor: pointer;
}
.headRight .actived .redx {
  width: 0.32rem;
  height: 0.06rem;
  margin: 0.12rem auto 0;
  transition: all 0.5s;
}
.headRight .actived:hover {
  color: #ffffff;
}
.headRights {
  display: flex;
  padding-top: 0.13rem;
  position: relative;
}
.headRights a {
  text-decoration: none;
}
.headRights div {
  color: rgba(51, 51, 51, 0.6);
  font-size: 0.16rem;
  margin-left: 0.28rem;
  cursor: pointer;
}
.headRights .actived .redx {
  width: 0.32rem;
  height: 0.06rem;
  margin: 0.12rem auto 0;
  transition: all 0.5s;
}
.headRights .actived:hover {
  color: #709c12;
}
.activess .actived {
  color: #ffffff;
  transition: all 0.5s;
}
.activess .redx {
  background: #ffffff;
}
.activessS .actived {
  color: #709c12;
  transition: all 0.5s;
}
.activessS .redx {
  background: #709c12;
}
.vertical {
  position: absolute;
  top: 0.15rem;
  right: 15%;
  width: 0.01rem;
  height: 0.24rem;
  background: rgba(255, 255, 255, 0.2);
}
.switch .switchImage {
  width: 0.24rem;
  height: 0.24rem;
  cursor: pointer;
}
.switchImage img {
  width: 100%;
  height: 100%;
}
/* 切换 */
.tabindex {
  width: 100%;
  height: 0;
  background-color: #709c12;
  position: absolute;
  top: 0;
  transition: all 0.5s;
  z-index: -1;
  opacity: 0;
}
.change {
  height: 5.02rem;
  opacity: 1;
  z-index: 9999;
}
.huisheng {
  padding: 0.2rem 2.4rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.huiImage {
  width: 0.195rem;
  height: 0.1934rem;
  cursor: pointer;
}
.huiImage img {
  width: 100%;
  height: 100%;
  display: block;
}
.briefBox {
  padding: 0.7rem 2.88rem 0 2.4rem;
}
a {
  text-decoration: none;
}
.briefBox ul {
  display: flex;
  font-size: 0;
  padding: 0;
  justify-content: space-between;
}
.briefBox ul li {
  cursor: pointer;
}
.briefBox ul li thead {
  color: #ffffff;
  font-size: 0.3rem;
  font-weight: 700;
}
.briefBox ul li tr {
  display: flex;
  flex-wrap: wrap;
  padding-top: 0.12rem;
}
.briefBox ul li tr td {
  display: block;
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.16rem;
  font-weight: 400;
  padding-top: 0.2rem;
  transition: all 0.3s;
}
.briefBox ul li tr td:hover {
  color: #ffffff;
}
</style>
