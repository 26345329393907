import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueI18n from 'vue-i18n'
// icon
import './assets/font/iconfont.css'
// 页面渐现
import 'wowjs/css/libs/animate.css'
import wow from 'wowjs'
Vue.prototype.$wow = wow
// 屏幕滚动（首页）
import 'fullpage.js/dist/fullpage.css';
import 'fullpage.js/vendors/scrolloverflow';
import VueFullPage from 'vue-fullpage.js';
Vue.use(VueFullPage);

// 中英文切换
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: localStorage.getItem('languageSet') || 'ZH',
  messages: {
    'ZH': require('./components/language/zh'),
    'EN': require('./components/language/en'),
  }
})
// element ui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

// rem
import './router/rem'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
