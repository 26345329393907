module.exports = {
    language: {
        name: 'ZH'
    },
    HeadersData: {
        shouye: 'Home',
        zoujin: 'About us',
        gogo: 'About Huisheng',
        chanpin: 'Products of Huisheng',
        xinwen: 'News of Huisheng',
        jituan: 'Huisheng group',
        lianxi: 'Contact us',
        yingye:'Business license',
        contact_style: 'contact information',
        contact_phone: 'contact number',
        brand_web:'BY：ZLYGU',
        mphpne: 'Tel',
        youbian:'Post Code',
        chuanzhen: 'Fax',
        dizhi:'Address',
        returnTop: 'Top'
    },
    navigator: {
        navIndex:'index',
        navBiref:'biref',
        navPro: 'fabric',
        navtec: 'skill',
        navNew: 'news',
        navDao: 'navbar'
    },
    cityText: 'Information publicity',
    homeView: {
        home: 'Home',
        tion:'Introduction of',
        brief: ' Huisheng Group',
        cp: 'huisheng',
        js: 'technology',
        xw: ' huisheng',
        nw:'News of',
        dh: 'Navigation',
        huisheng:'Huisheng',
        register:'register',
        honorG:'Glories of ',
        shares: 'shares',
        plain: 'pingyuan',
        sulation: 'insulation',
        gufen: 'shares',
        sales: 'sale',
        pack: 'packing',
        productM:'Products of ',
        loginUsername:'please input email or phone',
        xx:'Information',
        gs:'publicity'
    },
    gopage: 'About us',
    pageB: {
        birefText:'Introduction of Huisheng group',
        cultureText:'Culture of Huisheng',
        culture: 'culture of',
        techM:'Technology of  ',
        techText:'Technology of Huisheng',
        honorText:'Huisheng honors',
        tead:'Doing practical',
        incom: ' work',
        deve:'Glories of ',
        honr: 'honors'
    },
    product:'Products of Huisheng',
    productB: {
        paperText:'Core Board',
        tubeText: 'Paper Tube',
        cardText: 'Insulation Pressboard',
        mold: 'Insulation Components'
    },
    newText:'News of Huisheng',
    groupText: 'Huisheng group',
    groupB: {
        shares:'Huisheng shares',
        plain: 'Huisheng pingyuan',
        sulation: 'Huisheng insulation',
        sales: 'Huisheng sales',
        pack:'Huisheng packaging',
        website: 'Huisheng packaging official website'
    },
    contactText:'Contact us',
    images: {
        down: 'Be down to earth',
        downwo: "and make achievements",
        value: 'Seek values for',
        valuebold: 'our customers',
        make: 'peruse interests for',
        makebold: "our staff",
        wider: "make benefits for",
        widerbold: "our shareholders",
        ability: "Develop by ability and",
        abilitytwo: "survive by quality",
        limit: "Challenge the limit and",
        limitwo: "strive for the first",
        sine: "Integrity and stability",
        sinetwo: "excellence and win-win",
        dusty: "Lead the industry and",
        dustytwo: "enrich the employees",
    },
    birefDetail: 'Huisheng Group, founded in January 2001, is one of the leading enterprises in the industries of core board, paper tube, insulation pressboard and insulation components in China, headquartered in High-tech Zone of Weifang, Shandong Province. It has subsidiaries such as Huisheng Group Co., LTD., Huisheng pingyuan Paper Co., LTD., Weifang Huisheng Insulation Technology Co., LTD., Huisheng Sales Co., LTD., Huisheng Packaging Technology Co., LTD  ect .',
    productText: 'Products of Huisheng',
    lookText:'read more',
    nomore:'No more',
    lookView:'View details',
    purper: {
        tubeTitle:'Core Board',
        tubePolicy:'Quality Policies: stable and leading',
        rawText:'High quality raw material',
        equText:'Advanced technical equipment',
        runText:'Strict quality management',
        manText:'Excellent craftsmanship spirit',
        tubeTitles:'Paper tube',
        tubeTitless:'Insulation Pressboard',
        tubeTitlesss:'Insulation Components',
    },
    tab: {
        iconText: 'Core Board',
        iconText1:'Paper tube',
        iconText2:'Insulation Pressboard',
        iconText3:'Insulation Components',
    },
    tech:'Technology of',
    technologyed: 'Huisheng',
    technologyText:'Key High-tech Enterpris of the National Torch Program',
    technology: {
        firmyText:'Shandong Star',
        frimyBrief:'"Shandong Star Enterprise with Chinese patent"',
        frimyText2:'International advanced level',
        frimyBrief2:'F500,F600,F800 core board has reached the international advanced level',
        frimyText3:'Fill the domestic blank',
        frimyBrief3:'The performance of high-density insulation pressboard for UHV transformer has reached the international advanced level. 8mm thick UHV high-density insulation pressboard fills the domestic blank.',
        frimyText4:'Leading technology level in china',
        frimyBrief4: 'Four achievements, including FB series explosion-proof core board  and special core board for cone tube ect ,have filled the domestic blank, and the technology has reached the advanced level in China.'
    },
    patentBox: {
        patent1:'Shandong Star',
        patentBrief1:'"Shandong Star Enterprise with Chinese patent"',
        patent2:'International advanced level',
        patentBrief2:'F500,F600,F800 core board has reached the international advanced level',
        patent3:'Fill the domestic blank',
        patentBrief3:'The performance of high-density insulation pressboard for UHV transformer has reached the international advanced level. 8mm thick UHV high-density insulation pressboard fills the domestic blank.',
        patent4:'Leading technology level in china',
        patentBrief4:'Four achievements, including FB series explosion-proof core board  and special core board for cone tube ect ,have filled the domestic blank, and the technology has reached the advanced level in China.'
    },
    // 走进汇胜
    gopageText: {
        credit1:'Huisheng Group, founded in January 2001, is one of the leading enterprises in the industries of core board, paper tube, insulation pressboard and insulation components in China.Headquartered in High-tech Zone of Weifang, Shandong Province, it has two production bases in high-tech district of Weifang city and Pingyuan county, Dezhou,and has subsidiaries such as Huisheng Group Co., LTD., Huisheng pingyuan Paper Co., LTD., Weifang Huisheng Insulation Technology Co., LTD., Huisheng Sales Co., LTD., Huisheng Packaging Technology Co., LTD  ect .',
        credit2:'The products are sold well all over the country, and are exported to more than 20 countries, such as Japan, South Korea, and India etc., widely praised by customers. ',
        credit3:'',
        credit4:'',
        credit5:''
    },
    theme: {
        topical:'CULTURAL THEME',
        work:'Doing practical work',
        down:'be down to earth and make achievements',
        idea:'CORE CONCEPT',
        vision:'Vision',
        industry:'Lead the industry and enrich the employees',
        worth:'Core Values',
        quality:'Develop by ability and survive by quality',
        spirit:'Core Values',
        prise:'Challenge the limit and strive for the first',
        misson:'Mission',
        interest:'Seek values for our customers,persue interests for our staffs and make benefits for our shareholders',
        special:'SPECIAL CONCEPT',
        manage:'Business philosophy',
        steady:'Integrity and stability,excellence and win-win',
        admin:'Management philosophy',
        simple:'Fine and controllable,simple and fair',
        opera:'Working philosophy',
        everyday:'Cherish every day and do every point well',
        ploy:'Talent philosophy',
        respect:'Respecting employees,training employees,helping employees to succeed',
        chara:'CHARACTERISTIC CULTURE',
        since:'The Integrity Culture',
        compe:'The Competition Culture',
        power:'The Decentralization Culture',
        grat:'The Gratitude Culture',
        thank:'The Simplicity Culture',
        theory:'CHARACTERISTIC THEORY',
        edge:'Three-Level Theory of Competitiveness',
        priate:'Theory on right person for right position',
        progress:'Theory on improvement',
        strategy:'Strategy of ',
        tegy:'Huisheng'
    },
    start: {
        startText1:'Strive to be stronger and bigger in the industry of core board, paper tube,insulation pressboard and insulation components.',
        startText2:'Insist on technological upgrading, stable and leading product quality,constantly improved cost efficiency.',
        startText3:'Establish an advanced corporate management system and shape an advanced corporate culture.',
        startText4:'Persist in innovative development.',
        startText5:'Emphasize talent first and keep the staff’s income at the leading level in the local area.'
    },
    art: {
        artTitle:'Key High-tech Enterpris of the National Torch Program',
        artLi1:'Shandong Star Enterprise with Chinese patent ',
        artLi2:'The R & D and application technology of F500,F600,F800 core board has reached the international advanced level.',
        artLi3:'The performance of high-density insulation pressboard for UHV transformer has reached the international advanced level. 8mm thick UHV high-density insulation pressboard fills the domestic blank.',
        artLi4:'Four achievements, including FB series explosion-proof core board  and special core board for cone tube ect ,have filled the domestic blank, and the technology has reached the advanced level in China.'
    },
    circle: {
        circleText1:'Equip with the advanced production assembly line with the annual production capacity of 649,000 tons.',
        circleText2:'Produce core board (Grade E-F800),BT core board （BTB-BT200）and FB core board（FB300, FB500）.Have a full range of the three major series of core board.',
        circleText3:'The product quality is stable and the strength indicators and performance is at the leading level in China.',
        circleText4:'With outstanding domestic research and development team of core baord for paper tubes. Have Shandong Province Enterprise Technology Center and Weifang Core Board Engineering Technology Research Center. Equip with advanced R & D equipment. The research and development of new products is at the leading level in China.',
    },
    circle2: {
        circleText1:'Select the good quality core board from our own company for production.',
        circleText2:'Specialize in producing first-class spinning paper tubes and various high-grade paper tubes used in papermaking,film,non-woven fabric,metallurgy,food packaging and other industries.',
        circleText3:'It can produce paper tubes with inner diameter from 25mm to 605mm and wall thickness from 2mm to 30mm. The main products include high straightness BOPP film special paper tube; high grade seamless polishing paper tube with no seam,no wave,high finish,high true roundness,high straightness,high hardness and cleanliness;large diameter metallurgical paper tube, with high compressive strength, high flatness,stable  quality and other characteristics.',
        circleText4:'Huiseng provides high-quality spinning paper tubes for cotton, wool, viscose and yarn products with its patented technology, featured with  smooth small seams, high roundness, high dimensional accuracy, high strength, fine and clean surface of yarn grinding , high temperature resistance, high water resistance, etc. No matter in the new era of automatic high-speed winding machine, double twist machine, vortex spinning and air spinning ,Huisheng high quality spinning paper tube can help you to improve the production efficiency and product quality.',
    },
    circle3: {
        circleText1:'The raw material is imported e-grade unbleached sulphate soft wood pulp.',
        circleText2:'We have advanced technology,leading process and several invention patents.We can produce the insulation pressboard with a thickness from 0.8mm to 8.0 mm stably.',
        circleText3:'The products quality conforms to the standards of IEC60641-3-1-2008 and GBT19264.3-2013. The products have passed the inspection of Guilin Electric Power Research Institute (Electrical Materials Quality Supervision And Testing Center of Machinery Industry of China ) and passed the three tests of MSDS、REACH and ROHS of German TUV lab in Germany.',
    },
    circle4: {
        circleText1:'The designed annual capacity of insulation components is 6863 tons,including 3000 tons of insulation components,3743 tons of laminated board and 1.5 million pieces of machine moulded components.',
        circleText2:'Lead exit of 1000KV and a full set of hand moulded components can be produced.',
        circleText3:'Machine moulded components and hand moulded components are tested by X-ray testing equipment.',
    },
    paperbox: { 
        paperText: 'Huisheng Group Co., Ltd. is located in the High-tech zone, Weifang City, Shandong Province,which is the leading company of high-grade core board  with an annual production capacity of 299,000 tons. Its products are mainly used in high-strength chemical fiber tubes, special tubes for spinning, industrial paper tubes for papermaking, paper tubes for plastic films, and large-diameter paper tubes for metallurgy ect. It is the supplier of many well-known steel, chemical fiber, textile enterprises at home and abroad.',
        paperText2:'Huisheng Pingyuan Paper Co., Ltd. is located in Pingyuan County, Dezhou City, Shandong Province,which is the leading company of high-grade core board  with an annual production capacity of 350,000 tons. Its products are mainly used for high-strength chemical fiber tubes, special tubes for spinning, industrial paper tubes for papermaking, paper tubes for plastic films, and large-diameter paper tubes for metallurgy. It is the supplier of many well-known steel, chemical fiber, textile enterprises at home and abroad.',
        paperText3:'Weifang Huisheng Insulation Technology Co., Ltd. is located in the High-tech Zone, Weifang City, Shandong Province,which is a professional manufacturer of insulation pressboard  and insulation components for high-voltage and UHV transformers. The products are sold at home and abroad, and it is the supplier of many well-known power groups at home and abroad.',
        paperText4:'Huisheng Sales Co., Ltd. is the sales unit of Huisheng core board . Its sales network covers the whole of china  and parts of the world, and it is dedicated to provide domestic and foreign partners with the highest quality and reliable products and services.',
        paperText5:'Huisheng Packaging Technology Co., Ltd. has two production bases in High-tech Zone of Weifang City and Pingyuan County, Dezhou City in shandong province . It is a professional manufacturer of paper tubes with an annual production capacity of 60,000 tons,Specialized in the production of various high-grade high-quality paper tubes used in film, papermaking, metal foil, non-woven, glass fiber cloth and other industries.',
    }
}