import Vue from 'vue'
import Router from 'vue-router';
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import index from '../views/index.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/HomeView',
    name: 'HomeView', 
    component: HomeView,
  },
  {
    path: "/goPage",
    name: "goPage",
    component: resolve => require(['../views/goPage.vue'], resolve)
  },
  {
    path: "/product",
    name: "product",
    component: resolve => require(['../views/product.vue'], resolve)
  },
  {
    path: "/group",
    name: "group",
    component: resolve => require(['../views/group.vue'], resolve)
  },
  {
    path: "/news",
    name: "news",
    component: resolve => require(['../views/news.vue'], resolve)
  },
  {
    path: "/newsDetail",
    name: "newsDetail",
    component: resolve => require(['../views/newsDetail.vue'], resolve)
  },
  {
    path: "/contact",
    name: "contact",
    component: resolve => require(['../views/contact.vue'], resolve)
  },
  {
    path: "/yingye",
    name: "yingye",
    component: resolve => require(['../views/yingye.vue'], resolve)
  },
  {
    path: "/city",
    name: "city",
    component: resolve => require(['../views/city.vue'], resolve)
  },
  {
    path: "/phoneIndex",
    name: "phoneIndex",
    component: resolve => require(['../views/phone/phoneIndex.vue'], resolve)
  },
  {
    path: "/phoneGopage",
    name: "phoneGopage",
    component: resolve => require(['../views/phone/phoneGopage.vue'], resolve)
  },
  {
    path: "/phoneProduct",
    name: "phoneProduct",
    component: resolve => require(['../views/phone/phoneProduct.vue'], resolve)
  },
  {
    path: "/phoneNews",
    name: "phoneNews",
    component: resolve => require(['../views/phone/phoneNews.vue'], resolve)
  },
  {
    path: "/phoneNewsDetail",
    name: "phoneNewsDetail",
    component: resolve => require(['../views/phone/phoneNewsDetail.vue'], resolve)
  },
  {
    path: "/phoneGroup",
    name: "phoneGroup",
    component: resolve => require(['../views/phone/phoneGroup.vue'], resolve)
  },
  {
    path: "/phoneContent",
    name: "phoneContent",
    component: resolve => require(['../views/phone/phoneContent.vue'], resolve)
  },
  {
    path: "/phoneCity",
    name: "phoneCity",
    component: resolve => require(['../views/phone/phoneCity.vue'], resolve)
  },
]

const router = new VueRouter({
  routes
})

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router